<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">提现审核</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input v-model="query.equipment_number" placeholder="设备编号" />
				</a-form-model-item>
				<a-form-model-item label="硬件编号">
					<a-input v-model="query.hardware_number" placeholder="硬件编号" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="area" slot-scope="text, record">
				<a-space>
					{{ record.hospital_name + '-' + record.department_name }}
				</a-space>
			</span>
			<span slot="status" slot-scope="text, record">
				<a-space>
					{{ record.is_zuofei == -1 ? '驳回' : (record.shenhe_status == -1 ? '未审核' : ((record.shenhe_status ==
						1 && record.withdrawal_status == 1)) ? 已打款 : '其他未知状态') }}
				</a-space>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a-popconfirm v-if="(record.shenhe_status == -1)" title="确认通过改审核" ok-text="是" cancel-text="否"
						@confirm="toAccess(record)">
						<a>通过</a>
					</a-popconfirm>
					<a v-if="(record.shenhe_status == -1)" @click="toRefuse(record)" style="color: #E0352B">驳回</a>
				</a-space>
			</span>
		</a-table>
		<template>
			<a-modal title="驳回理由" :visible="visible" ok-text="提交" cancel-text="取消" :confirm-loading="confirmLoading"
				@ok="submitInfo" @cancel="handleCancel">
				<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="equipmentForm" :model="form"
					:rules="info_rules">
					<a-form-model-item prop="reason" label="理由：">
						<a-textarea v-model="form.reason"></a-textarea>
					</a-form-model-item>
				</a-form-model>
			</a-modal>
		</template>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			withdrawal_id: null,
			form: {},
			pagetotal: 0,
			visible: false,
			confirmLoading: false,
			equipment: null,
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '昵称',
				dataIndex: 'name'
			},
			{
				title: '手机号',
				dataIndex: 'phone'
			},
			{
				title: '所属医院',
				scopedSlots: {
					customRender: 'area'
				}
			},
			{
				title: '账户信息',
				dataIndex: 'open_id'
			},
			{
				title: '提现金额',
				dataIndex: 'withdrawal_money'
			},
			{
				title: '状态',
				scopedSlots: {
					customRender: 'status'
				}
			},
			{
				title: '申请时间',
				dataIndex: 'create_at_str'
			},
			{
				title: '操作',
				scopedSlots: {
					customRender: 'action'
				}
			}
			],
			data: [],
			selectedRowKeys: [],
			info_rules: {
				reason: [
					{
						required: true,
						message: '填写驳回理由',
						trigger: 'blur'
					}
				]
			},
		}
	},
	created() {
		this.getList()
	},
	methods: {
		handleCancel() {
			this.visible = false;
			this.initForm()
		},
		toRefuse(item) {
			console.log('object', item)
			this.withdrawal_id = item.withdrawal_id;
			this.visible = true;
		},
		initForm() {
			this.form = {}
			this.withdrawal_id = null
		},
		submitInfo() {
			this.$refs.equipmentForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true

					this.$post("reconciliation/withdrawalShenhe", {
						'withdrawal_id': this.withdrawal_id,
						'type': -1,
						'reason': this.form.reason
					})
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.handleCancel()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		toAccess(id) {
			this.$post("reconciliation/withdrawalShenhe", {
				'withdrawal_id': id,
				'type': 1
			})
				.then(res => {
					let { code, msg, data } = res
					this.confirmLoading = false
					if (code == 0) {
						this.$message.success(msg, 1.5)
						this.getList()
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.confirmLoading = false
				})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/reconciliation/withdrawalList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList();
			console.log(query)
		},
		changeCheck(value) {
			if (value.length === 1) {
				this.query.is_on = value[0]
			} else {
				this.query.is_on = null
			}
			this.getList()
		},
		// onSelectChange(selectedRowKeys) {
		// 	console.log('选中中', selectedRowKeys)
		// 	this.selectedRowKeys = selectedRowKeys
		// }
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
